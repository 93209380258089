import { getMultipleQueryValueWithNumber, getBooleanQueryValue, transformQueryBoolean } from '@/util'

export const FILTER_NAMES = Object.freeze({
  CITY: 'city',
  HIDE_COURSES_WITHOUT_RESERVATION: 'hide_empty_courses',
  PAGE: 'page',
  DATE_FROM: 'date_after',
  DATE_TO: 'date_before'
});

export class CMOverviewFilters {
  constructor(filters = {}) {
    this[FILTER_NAMES.CITY] = getMultipleQueryValueWithNumber(filters[FILTER_NAMES.CITY]);
    this[FILTER_NAMES.HIDE_COURSES_WITHOUT_RESERVATION] = FILTER_NAMES.HIDE_COURSES_WITHOUT_RESERVATION in filters ? 
      transformQueryBoolean(filters[FILTER_NAMES.HIDE_COURSES_WITHOUT_RESERVATION]) : true
    this[FILTER_NAMES.PAGE] = filters[FILTER_NAMES.PAGE] || 1;
    this[FILTER_NAMES.DATE_FROM] = filters[FILTER_NAMES.DATE_FROM] || '';
    this[FILTER_NAMES.DATE_TO] = filters[FILTER_NAMES.DATE_TO] || '';
  }

  setFilter(filterName, value) {
    if (!Object.keys(this).includes(filterName)) throw new Error('No such filter in model');

    // Clear page before every filter update, besides page filter
    if (filterName !== FILTER_NAMES.PAGE) {
      this.clearFilter(FILTER_NAMES.PAGE)
    }

    this[filterName] = value;
  }

  clearAllFilters() {
    const keys = Object.keys(this);
    keys.forEach((key) => {
      this.clearFilter(key)
    });
  }

  /*
    @payload:
      date_from: Date,
      date_to: Date
 */
  setDateFilter(payload) {
    this.setFilter(FILTER_NAMES.DATE_FROM, payload.date_from);
    this.setFilter(FILTER_NAMES.DATE_TO, payload.date_to);
  }

  clearFilter(filterName) {
    if (Array.isArray(this[filterName])) {
      this[filterName] = []
    } else if (typeof this[filterName] === "boolean"){
      this[filterName] = false;
    } else {
      this[filterName] = null
    }

  }

  get activeFilters() {
    const activeFilters = {};
    Object.keys(this).forEach((key) => {
      if (this[key]) {
        activeFilters[key] = this[key];
      }
    });
    return activeFilters;
  }
}
