<template lang="pug">
  .page-container.master-planning
    portal(to="toolbar-title")
      toolbar-title Master Planning

    v-container(fluid).course-list
      .course-list
        v-data-table(
          :headers="headers"
          :items="list"
          item-key="title"
          fixed-header
          :expanded.sync="expanded"
          disable-sort
          :server-items-length="list.length"
          hide-default-footer
          hide-default-header
          class="course-list__table"
        )

          template(v-slot:header="headerRow")
            cm-overview-course-header(
              :headerRow="headerRow"
              :filters="filters"
              @updateFilters="updateFilter"
              @dateFilterChanged="updateDateFilter"
            )
          template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
            cm-overview-course-week-row(v-if="showWeekHeader(item, index)" :title="getWeekHeaderTitle(item) ")
            cm-overview-course-group-row(
              data-test="cm-group-row"
              :item="item"
              :isExpanded="isExpanded"
              @click="expand(!isExpanded)"
            )

          template(v-slot:expanded-item="{ headers, item }")
            td.p-0(:colspan="headers.length")
              cmOverviewExpandedItem(
                :ref="`expanded-${item.value}`"
                :item="item"
                @updateList="reloadList"
              )
</template>

<script>
import Svc from "./core/cm-service"
import { CMOverviewFilters, FILTER_NAMES } from './classes/CMOverviewFilters'
import PaginationModel from "@/models/paginationModel"
import errorsMixin from '@/mixins/errors.mixin'
import dayjs from 'dayjs'

const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  mixins: [errorsMixin, featureFlagsMixin],
  provide: function () {
    return {
      svc: this.svc,
    }
  },
  data: () => ({
    expanded: [],
    filters: new CMOverviewFilters(),
    svc: new Svc(),
    pagination: null,
    list: [],
    headers: [
      {text: "Day & Day of week", value: "date"},
      {text: "Name", value: "name"},
      {text: "Location", value: "location", icon: '$vuetify.icons.values.location'},
      {text: "Exam location", icon: '$vuetify.icons.values.location.checkboxCircle'},
      {text: "Exam date", icon: '$vuetify.icons.values.location.calendar'},
      {text: "Exam time", icon: '$vuetify.icons.values.location.clockOutline'},
      {value: "switcher"},
      {text: "Actions"},
    ],
  }),
  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return false
      if (this.loading) return true
      if (this.pagination && !this.pagination.next) return true
      return false
    },
  },
  created() {
    this.setFilters(this.$route.query);
    this.loadList();
  },
  methods: {
    showWeekHeader(item, index) {
      if (index === 0) return true
      return dayjs(this.list[index - 1].value).isoWeek() !== dayjs(item.value).isoWeek()
    },
    getWeekHeaderTitle(item) {
      const currentWeek = dayjs().isoWeek()
      const nextWeek = currentWeek + 1

      if (item.value === 'null') return ''
      if (dayjs(item.value).isoWeek() === currentWeek) return 'Deze week'
      if (dayjs(item.value).isoWeek() === nextWeek) return 'Volgende week'
      return `Week ${dayjs(item.value).isoWeek()}`

    },
    /*
        @payload:
          filter: String,
          value: String, Number, Array
       */
    async updateFilter(payload) {
      this.filters.setFilter(payload.filter, payload.value);
      await this.updateRouteWithQueryParams(this.filters.activeFilters);
      await this.loadList({});
      this.updateCoursesInExpanded();
    },
    updateCoursesInExpanded() {
      this.expanded.forEach(expand => {
        if (this.$refs[`expanded-${expand.value}`]) {
          this.$refs[`expanded-${expand.value}`].getCourses({})
        }
      })
    },
    async updateDateFilter(date) {
      this.filters.setDateFilter(date);
      await this.updateRouteWithQueryParams(this.filters.activeFilters);
      this.loadList({});
    },
    updateRouteWithQueryParams(query) {
      this.$router.replace({name: this.$route.name, query});
    },
    setCityFilter() {
      this.filters.setFilter(FILTER_NAMES.CITY, +this.$route.query.city)
    },
    setFilters(filters) {
      this.filters = new CMOverviewFilters(filters);
    },
    reloadList() {
      this.loadList();
    },
    async loadList() {
      try {
        this.list = await this.svc.courseListOnlyDates({...this.filters.activeFilters})
      } catch (err) {
        this.processError(err, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    cmOverviewCourseRow: () => import('./components/CMOverviewCourseRow.vue'),
    cmOverviewCourseGroupRow: () => import('./components/CMOverviewCourseGroupRow.vue'),
    cmOverviewCourseWeekRow: () => import('./components/CMOverviewCourseWeekRow.vue'),
    cmOverviewCourseHeader: () => import('./components/CMOverviewHeader.vue'),
    cmOverviewExpandedItem: () => import('./components/CMOverviewExpandedItem.vue')
  }
}
</script>

<style lang="scss" scoped>

.loader-wrapper {
  background-color: white;
}

.p-0 {
  padding: 0;
}

.course-list {

  @media screen and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }

  &__table {
    &::v-deep .v-data-table__wrapper {
      @extend %visible-scrollbar;
      position: relative;
      overflow: auto;
      max-height: calc(100vh - 92px);
    }

    // &::v-deep th{
    //   z-index: 0;
    // }
  }
}
</style>
