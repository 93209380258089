var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container master-planning"},[_c('portal',{attrs:{"to":"toolbar-title"}},[_c('toolbar-title',[_vm._v("Master Planning")])],1),_c('v-container',{staticClass:"course-list",attrs:{"fluid":""}},[_c('div',{staticClass:"course-list"},[_c('v-data-table',{staticClass:"course-list__table",attrs:{"headers":_vm.headers,"items":_vm.list,"item-key":"title","fixed-header":"","expanded":_vm.expanded,"disable-sort":"","server-items-length":_vm.list.length,"hide-default-footer":"","hide-default-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(headerRow){return [_c('cm-overview-course-header',{attrs:{"headerRow":headerRow,"filters":_vm.filters},on:{"updateFilters":_vm.updateFilter,"dateFilterChanged":_vm.updateDateFilter}})]}},{key:"item",fn:function(ref){
var item = ref.item;
var select = ref.select;
var isSelected = ref.isSelected;
var index = ref.index;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(_vm.showWeekHeader(item, index))?_c('cm-overview-course-week-row',{attrs:{"title":_vm.getWeekHeaderTitle(item)}}):_vm._e(),_c('cm-overview-course-group-row',{attrs:{"data-test":"cm-group-row","item":item,"isExpanded":isExpanded},on:{"click":function($event){return expand(!isExpanded)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"p-0",attrs:{"colspan":headers.length}},[_c('cmOverviewExpandedItem',{ref:("expanded-" + (item.value)),attrs:{"item":item},on:{"updateList":_vm.reloadList}})],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }